import SbPolyfill from "SB/js/polyfill";

(() => {
    //hide contact fab when footer form is visible
    let contactFab = document.querySelector('.contact-fab');
    // let contactHeader = document.querySelector('.header-banner');
    let contactHeader = false;
    let contactHeaderButton = document.querySelector('.header-banner .button-contact');
    let contactFooter = document.querySelector('.footer');
    let pagination = document.querySelector('.pagination-page');

    let items = document.querySelectorAll('.projects-featured-home .item');

    SbPolyfill('intersection-observer')
    .then(() => {
        if (contactHeaderButton) {
            const headerObserver = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        contactFab && contactFab.classList.remove('active');
                        pagination && pagination.classList.remove('active');
                    } else {
                        contactFab && contactFab.classList.add('active');
                        pagination && pagination.classList.add('active');
                    }
                });
            }, {threshold: [0]});

            headerObserver.observe(contactHeaderButton);
        } else if (contactHeader) {
            const headerObserver = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio >= 0.8) {
                        contactFab && contactFab.classList.remove('active');
                        pagination && pagination.classList.remove('active');
                    } else {
                        contactFab && contactFab.classList.add('active');
                        pagination && pagination.classList.add('active');
                    }
                });
            }, {threshold: [0.8]});

            headerObserver.observe(contactHeader);
        } else {
            contactFab && contactFab.classList.add('active');
            pagination && pagination.classList.add('active');
        }

        if (contactFooter) {
            const formObserver = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        contactFab.classList.add('form-visible');
                    } else {
                        contactFab.classList.remove('form-visible');
                    }
                });
            }, {threshold: [0]});

            formObserver.observe(contactFooter);
        }


        //apply classes to items for effects
        const itemObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, {threshold: [0.4]});

        items.forEach((item) => {
            itemObserver.observe(item);
        });
    });

})();
